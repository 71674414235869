<template>
  <div>
    <h1>Page not found</h1>

    <p>Unfortunately we couldn't find the page you were after.</p>

    <p>Let us know if this issue continues</p>
  </div>
</template>

<script>
export default {
  name: "404"
};
</script>

<style lang="scss" scoped>
h1 {
  margin: 20px;
}
</style>
